<template>
  <Details
    title="person"
    resource="persons"
    :base-path="basePath"
    data-provider="$raaDataProvider"
  >
    <template v-slot:side-panel>
      <DetailsSidePanel entity-title="person" new-entity></DetailsSidePanel>
    </template>
    <template v-slot:default="detailsProps">
      <PersonForm
        :initial-values="initialValues"
        :on-submit="detailsProps.handleSubmit"
        :loading="detailsProps.loading"
        @close="detailsProps.redirectToList"
      />
    </template>
  </Details>
</template>

<script>
  import PersonForm from "@/views/auth/raa-devtools/persons/PersonForm";
  import Details from "@/components/auth/details/Details";
  import DetailsSidePanel from "@/components/auth/details/DetailsSidePanel";
  import {v4} from "uuid";
  import ModalNavigation from "@/mixins/ModalNavigation";

  export default {
    name: "PersonCreate",
    components: {PersonForm, Details, DetailsSidePanel},
    mixins: [ModalNavigation],
    computed: {
      basePath: function () {
        return this.$route.name.replace('create', 'index');
      },
      initialValues() {
        return {id: v4()}
      }
    }
  }
</script>

